<template>
  <div>
    <v-row>
      <v-container fluid>
        <h1 class="success--text text-center my-5">Dados do cliente</h1>
      </v-container>
      <v-col cols="4">
        <p class="mt-3 blue--text">Nome do cliente</p>
        <p>{{ getOneReserva.nome }}</p>
      </v-col>
      <v-col cols="4">
        <p class="mt-3 blue--text">Email</p>
        <p>{{ getOneReserva.email }}</p>
      </v-col>
      <v-col cols="4">
        <p class="mt-3 blue--text">Telefone</p>
        <p>{{ getOneReserva.telefone }}</p>
      </v-col>
      <v-col cols="4">
         <p class="mt-3 blue--text">Observação</p>
        <p>{{ getOneReserva.obs }}</p>
      </v-col>
      <v-col cols="4">
         <p class="mt-3 blue--text">Pago</p>
        <p>{{ getOneReserva.pago ? 'Sim' : 'Não' }}</p>
      </v-col>
        <v-col cols="4">
         <p class="mt-3 blue--text">Entrega</p>
        <p>{{ getOneReserva.entrega ? 'Sim' : 'Não' }}</p>
      </v-col>
    </v-row>
    <v-container fluid>
      <h1 class="success--text text-center mt-10" v-if="getOneReserva.cep">Endereço de entrega</h1>
    </v-container>
    <v-row v-if="getOneReserva.cep != ''">
      <v-col cols="4">
        <p class="mt-3 blue--text">Cep</p>
        <p>{{ getOneReserva.cep }}</p>
      </v-col>
      <v-col cols="4">
        <p class="mt-3 blue--text">Rua</p>
        <p>{{ getOneReserva.rua }}</p>
      </v-col>
      <v-col cols="4">
        <p class="mt-3 blue--text">Bairro</p>
        <p>{{ getOneReserva.bairro }}</p>
      </v-col>
      <v-col cols="4">
        <p class="mt-3 blue--text">Cidade</p>
        <p>{{ getOneReserva.cidade }}</p>
      </v-col>
      <v-col cols="4">
        <p class="mt-3 blue--text">Complemento</p>
        <p>{{ getOneReserva.complemento }}</p>
      </v-col>
      <v-col cols="2">
        <p class="mt-3 blue--text">Numero</p>
        <p>{{ getOneReserva.numero }}</p>
      </v-col>
      <v-col cols="2">
        <p class="mt-3 blue--text">UF</p>
        <p>{{ getOneReserva.uf }}</p>
      </v-col>
    </v-row>
    <v-container fluid>
      <h1 class="success--text text-center mt-10">Peças do cliente</h1>
    </v-container>
    <v-row class="my-5" v-for="(pecas, i) in getOneReserva.pecas" :key="i">
      <v-col cols="3">
        <p class="mt-3 blue--text">Quantidade</p>
        <p>{{ getOneReserva.pecas[i].qtd }}</p>
      </v-col>
      <v-col cols="3">
        <p class="mt-3 blue--text">Nome da peça</p>
        <p>{{ getOneReserva.pecas[i].nomePeca }}</p>
      </v-col>
          <v-col cols="3">
        <p class="mt-3 blue--text">Tamanho</p>
        <p>{{ getOneReserva.pecas[i].tam }}</p>
      </v-col>
            <v-col cols="3">
        <p class="mt-3 blue--text">Escola</p>
        <p>{{ getOneReserva.pecas[i].escola }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    getOneReserva() {
      return this.$store.getters["reservas/getOneReserva"];
    },
  },
};
</script>

<style>
h1 {
  color: #bdbdbd;
}
p {
  font-size: 1.3rem;
  font-weight: 500;
  color: #bdbdbd;
}
</style>