import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      email: "",
      name: "",
      _id: "",
      admin: "",
      store: "",
    },
    overlay: false
  },
  getters: {
    user: (state) => state.user,
    _id: (state) => state.user._id,
    admin: (state) => state.user.admin,
    store: (state) => state.user.store,
    overlay: (state) => state.overlay
  },
  mutations: {
    getUserData(state, payload) {
      state.user.email = payload.user.email;
      state.user.name = payload.user.nome;
      state.user._id = payload.user._id;
      state.user.admin = payload.user.admin;
      state.user.store = payload.user.store;
    },
    callLoading(state) {
      state.overlay = true
      setTimeout(() => {
        state.overlay = false;
      },2000)
    }
  },
  actions: {
    getUserData(context, payload) {
      context.commit("getUserData", payload);
    },
    callLoading(context) {
      context.commit('callLoading')
    }
  },
  modules: {
    pedidos: {
      namespaced: true,
      state: () => {
        return {
          pedidos: "",
          dialog: false,
          dialogEditPedido: false,
          tablePedido: {},
        };
      },
      getters: {
        getAllPedidos: (state) => state.pedidos,
        dialog: (state) => state.dialog,
        dialogEditPedido: (state) => state.dialogEditPedido,
        getOnePedido: (state) => state.tablePedido,
      },
      mutations: {
        setPedidos(state, payload) {
          state.pedidos = payload;
        },
        openPreview(state, payload) {
          state.dialog = true;
          state.tablePedido = payload;
        },
        openEdit(state, payload) {
          state.tablePedido = payload;
          state.dialogEditPedido = true;
        },
        closeDialog(state) {
          state.dialog = false;
        },
        closedialogEditPedido(state) {
          state.dialogEditPedido = false;
        },
      },
      actions: {
        setPedidos(context, payload) {
          context.commit("setPedidos", payload);
        },
        openPreview(context, payload) {
          context.commit("openPreview", payload);
        },
        openEdit(context, payload) {
          context.commit("openEdit", payload);
        },
        closeDialog(context) {
          context.commit("closeDialog");
        },
        closedialogEditPedido(context) {
          context.commit("closedialogEditPedido");
        },
      },
    },
    reservas: {
      namespaced: true,
      state: () => {
        return {
          reservas: "",
          dialogReserva: false,
          oneReserva: {},
          dialogEditReserva: false
        };
      },
      getters: {
        getAllReservas: state => {
          if (state.reservas) {
            return state.reservas
          }
        },
        getOneReserva: state => state.oneReserva,
        dialogReserva: state => state.dialogReserva,
        dialogEditReserva: state => state.dialogEditReserva
      },
      mutations: {
        setReservas(state, payload) {
          state.reservas = payload
        },
        openPreview(state, payload) {
          state.dialogReserva = true;
          state.oneReserva = payload;
        },
        closeDialogReserva(state) {
          state.dialogReserva = false
        },
        openEditReserva(state, payload) {
          state.oneReserva = payload;
          state.dialogEditReserva = true;
        },
        closeEditReserva(state) {
          state.dialogEditReserva = false;
        },
      },
      actions: {
        setReservas(context, payload) {
          context.commit('setReservas', payload)
        },
        openPreview(context, payload) {
          context.commit('openPreview', payload)
        },
        closeDialogReserva(context) {
          context.commit('closeDialogReserva')
        },
        openEditReserva(context, payload) {
          context.commit('openEditReserva', payload)
        },
        closeEditReserva(context) {
          context.commit('closeEditReserva')
        },
      }
    },
  },
});
