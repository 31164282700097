<template>
  <v-card class="secondary">
    <v-card-title>
      <v-text-field
        dark
        v-model="search"
        label="Pesquisar reservas"
        single-line
        append-icon="mdi-magnify"
        hide-details
        color="success"
        placeholder="Digite qualquer informação sobre a reserva"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="secondary"
      dark
      :headers="headers"
      :items="allReservas"
      :search="search"
      sort-by="_id"
      :sort-desc="true"
    >
      <template v-slot:item.acoes="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              center
              large
              color="success"
              v-bind="attrs"
              v-on="on"
              @click="openWats(props.item)"
            >
              <v-icon size="20"> mdi-whatsapp</v-icon>
            </v-btn>
          </template>
          <span>Enviar Whatsapp para o cliente</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              center
              large
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="sendEmail(props.item)"
            >
              <v-icon size="20"> mdi-email</v-icon>
            </v-btn>
          </template>
          <span>Enviar email para o cliente</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              center
              large
              color="yellow"
              v-bind="attrs"
              v-on="on"
              @click="acessarReserva(props.item)"
            >
              <v-icon size="20"> mdi-file-document</v-icon>
            </v-btn>
          </template>
          <span>Visualizar reserva</span>
        </v-tooltip>
         <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              center
              large
              color="blue"
              v-bind="attrs"
              v-on="on"
              @click="editarReserva(props.item)"
            >
              <v-icon size="20"> mdi-square-edit-outline </v-icon>
            </v-btn>
          </template>
          <span>Editar a reserva do cliente</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="deletarReserva(props.item._id)"
              v-bind="attrs"
              v-on="on"
              icon
              center
              large
              color="error"
            >
              <v-icon size="20"> mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Concluir reserva</span>
        </v-tooltip>
      </template>
    </v-data-table>
  <EditarReserva/>
  </v-card>
</template>

<script>
import EditarReserva from '../components/EditarReserva.vue'

export default {
  components: { EditarReserva },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nome do cliente",
          align: "start",
          sortable: false,
          value: "nome",
        },
        {
          text: "Telefone",
          value: "telefone",
          align: "center",
          sortable: false,
        },
        {
          text: "Turma(s)",
          value: "turma",
          align: "center",
          sortable: false,
        },
        {
          text: "Escola",
          value: "pecas[0].escola",
          align: "center",
          sortable: false,
        },
        {
          text: "Ações da reserva",
          value: "acoes",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    editarReserva(pedido) {
      this.$store.dispatch("reservas/openEditReserva", pedido);
    },
    acessarReserva(reserva) {
      this.$store.dispatch("reservas/openPreview", reserva);
    },
    openWats(reserva) {
      const reg = /\w+/g;
      const oldNumber = reserva.telefone.match(reg);
      const url = `https://wa.me/55${+oldNumber.join("")}`;
      window.open(url, "_blank");
    },
    deletarReserva(id) {
      this.$swal({
        customClass: {
          title: "swal2-title-success",
          confirmButton: "swal2-btn-confirm",
          cancelButton: "swal2-btn-cancel",
        },
        background: "#202A37",
        title: "Você realmente deseja finalizar essa reserva?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#16C177",
        cancelButtonColor: "#f27474",
        confirmButtonText: "Desejo finalizar a reserva!",
        cancelButtonText: "Cancelar!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('callLoading')
          this.$swal({
            customClass: {
              title: "swal2-title-success",
            },
            title: "Reserva finalizada com sucesso!",
            toast: true,
            position: "top-end",
            timerProgressBar: true,
            timer: 3000,
            showConfirmButton: false,
            icon: "success",
            background: "#202A37",
            width: 400,
          });
          this.$http.delete(this.$reservaApiRoute + id).then((res) => {
            this.$http.get(this.$reservaApiRoute).then((response) => {
              this.$store.dispatch(
                "reservas/setReservas",
                response.data.data.reserva
              );
            });
          });
        }
      });
    },
    sendEmail(reserva) {
      this.$swal({
        customClass: {
          title: "swal2-title-success",
          confirmButton: "swal2-btn-confirm",
          cancelButton: "swal2-btn-cancel",
        },
        background: "#202A37",
        title: "Você realmente deseja enviar email para esse cliente?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#16C177",
        cancelButtonColor: "#f27474",
        confirmButtonText: "Desejo avisar o cliente!",
        cancelButtonText: "Cancelar!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('callLoading')
          this.$swal({
            customClass: {
              title: "swal2-title-success",
            },
            title: "Email Enviado com successo!",
            toast: true,
            position: "top-end",
            timerProgressBar: true,
            timer: 3000,
            showConfirmButton: false,
            icon: "success",
            background: "#202A37",
            width: 400,
          });
          this.$http.post(this.$sendEmailRoute + "reserva/", reserva)
        }
      });
    },
  },
  computed: {
    allReservas() {
      return this.$store.getters["reservas/getAllReservas"];
    },
  },
  mounted() {
    this.$http.get(this.$reservaApiRoute).then((response) => {
      this.$store.dispatch("reservas/setReservas", response.data.data.reserva);
    });

    const getAsyncData = async () => {
      try {
        await this.$http.get(this.$reservaApiRoute).then((response) => {
          this.$store.dispatch(
            "reservas/setReservas",
            response.data.data.reserva
          );
        });

        await this.$store.getters["reservas/getAllReservas"];
      } catch (err) {
        console.log(err);
      }
    };

    getAsyncData();
  },
};
</script>