<template>
  <v-container>
    <v-container class="d-flex justify-center mt-16">
      <v-img max-width="300" src="../assets/vestementaLogo.svg"></v-img>
    </v-container>
    <v-row class="d-flex justify-center mt-5">
      <v-col class="mb-n8" cols="4">
        <v-text-field
          v-model="login.email"
          label="Email"
          class="text-green"
          color="success"
          background-color="secondary"
          filled
          dark
          prepend-inner-icon="mdi-email"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="4">
        <v-text-field
          v-model="login.password"
          label="Senha"
          background-color="secondary"
          filled
          dark
          prepend-inner-icon="mdi-lock"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show1 = !show1"
          :type="show1 ? 'text' : 'password'"
          :rules="[rules.required, rules.min]"
          color="success"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center" cols="12">
        <v-col cols="2">
          <v-btn @click="logar" large block class="success mt-n5">login</v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
      show1: false,
      rules: {
        required: (value) => !!value || "Requerido.",
        min: (v) =>
          v.length >= 6 || "Sua senha deve conter pelo menos 6 caracteres",
      },
    };
  },
  methods: {
    async logar() {
      try {
        const api = "https://vestementa-dash.herokuapp.com/api/v1/user";
        const data = await this.$http.post(api, this.login).then((res) => {
          if (res.status == 201) {
            this.$swal({
              customClass: {
                title: "swal2-title-success",
              },
              title: "Login efetuado com sucesso!",
              toast: true,
              position: "top-end",
              timerProgressBar: true,
              timer: 3000,
              showConfirmButton: false,
              icon: "success",
              background: "#202A37",
              width: 400,
            });
            this.$router.push({ name: "pedidos" });
            this.$store.dispatch('callLoading')
          }
          this.$store.dispatch("getUserData", res.data.data);
        });

      } catch (err) {
        if (err) {
          this.$swal({
            customClass: {
              title: "swal2-title2",
            },
            title: "Usuário e senha incorretos.",
            toast: true,
            position: "top-end",
            timerProgressBar: true,
            timer: 3000,
            showConfirmButton: false,
            icon: "error",
            background: "#202A37",
          });
          this.$roter.push({ path: "/" });
          this.$store.dispatch('callLoading')
        }
      }
    },
  },
};
</script>
