<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogEditPedido"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="primary">
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Editar pedido de {{ getOnePedido.name }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-container class="px-10">
          <RegistroPedido />
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import RegistroPedido from "./RegistoPedido.vue";

export default {
  components: { RegistroPedido },
  methods: {
    closeModal() {
      this.$store.dispatch("pedidos/closedialogEditPedido");
    },
  },
  computed: {
    dialogEditPedido() {
      return this.$store.getters["pedidos/dialogEditPedido"];
    },
    getOnePedido() {
      return this.$store.getters["pedidos/getOnePedido"];
    },
  },
};
</script>