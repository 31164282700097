import axios from 'axios';
import vuex from '../store/index'

export default function setup() {
    axios.interceptors.request.use(function(config) {
        const token = vuex.getters._id;
        const loja = vuex.getters.user.store
        const admin = vuex.getters.admin
        if(token) {
            config.headers.id = token;
            config.headers.loja = loja
            config.headers.admin = admin
        }
        return config;
    }, function(err) {
        return Promise.reject(err);
    });
}