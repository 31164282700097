<template>
  <v-card class="secondary py-5">
    <h1 class="ml-6 colorText--text text-center mt-3">
      Informações do cliente
    </h1>
    <v-container fluid class="d-flex justify-end">
      <v-radio-group v-model="radios" mandatory row>
        <v-radio
          label="Pessoa fisica"
          value="fisica"
          color="success"
          dark
        ></v-radio>
        <v-radio
          label="Pessoa juridica"
          value="juridica"
          color="success"
          dark
        ></v-radio>
      </v-radio-group>
    </v-container>
    <v-row class="mx-3 py-3">
      <v-col cols="4">
        <v-text-field
          v-model="pedido.name"
          label="Nome do cliente / Empresa"
          color="success"
          filled
          dark
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="pedido.email"
          label="Email"
          color="success"
          filled
          dark
        >
        </v-text-field>
      </v-col>
      <v-col cols="4" v-if="radios === 'fisica'">
        <v-text-field
          v-model="pedido.cpf"
          label="CPF"
          color="success"
          filled
          dark
          v-mask="'###.###.###-##'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4" v-if="radios === 'juridica'">
        <v-text-field
          v-model="pedido.cnpj"
          label="CNPJ"
          color="success"
          filled
          dark
          v-mask="'##.###.###/####-##' || ''"
        >
        </v-text-field>
      </v-col>
      <v-col cols="5">
        <v-text-field
          v-model="pedido.endereco"
          label="Endereço"
          color="success"
          filled
          dark
        >
        </v-text-field>
      </v-col>
      <v-col cols="5">
        <v-text-field
          v-model="pedido.complemento"
          label="Complemento"
          color="success"
          filled
          dark
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="pedido.numero"
          label="Número"
          color="success"
          filled
          dark
          v-mask="'######'"
          placeholder="12"
        >
        </v-text-field>
      </v-col>
      <v-col cols="5">
        <v-text-field
          v-model="pedido.bairro"
          label="Bairro"
          color="success"
          filled
          dark
        >
        </v-text-field>
      </v-col>
      <v-col cols="5">
        <v-text-field
          v-model="pedido.cidade"
          label="Cidade"
          color="success"
          filled
          dark
        >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="pedido.uf"
          label="UF"
          color="success"
          filled
          dark
          v-mask="'AA'"
          placeholder="UF"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="pedido.cep"
          label="Cep"
          color="success"
          filled
          dark
          v-mask="'##.###-###'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="pedido.telefone1"
          label="Telefone / WhatsApp"
          color="success"
          filled
          dark
          v-mask="'(##) #####-####'"
          placeholder="(27) 12345-1234"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="pedido.telefone2"
          label="Telefone Fixo"
          color="success"
          filled
          dark
          v-mask="'(##) ####-####'"
          placeholder="(27) 12345-1234"
        >
        </v-text-field>
      </v-col>

            <v-col cols="4">
        <v-select
          :items="['Gola careca', 'Gola polo', 'Máscara', 'Calça', 'Outros']"
          v-model="pedido.tipoGola"
          label="Modelo"
          color="success"
          filled
          dark
          :menu-props="{
            dark
          }"
        >
        </v-select>
      </v-col>

      <v-col cols="4">
        <v-select
          :items="['Viés', 'Ribana']"
          v-model="pedido.manga"
          label="Manga"
          color="success"
          filled
          dark
        >
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="['Gola V', 'Gola Redonda', 'Gola Polo']"
          v-model="pedido.gola"
          label="Tipo da Gola"
          color="success"
          filled
          dark
        >
        </v-select>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="pedido.pagamento"
          label="Pagamento"
          color="success"
          filled
          dark
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="pedido.data"
          label="Data de entrega do pedido"
          color="success"
          filled
          dark
          placeholder="dd/mm/aa"
          v-mask="'##/##/####'"
        >
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="pedido.vendedor"
          label="Vendedor"
          color="success"
          filled
          dark
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-3 py-3">
      <v-col cols="12">
        <h1 class="colorText--text text-center mt-2">
          Modelos e grade do pedido
        </h1>
      </v-col>
      <v-container class="mb-1 d-flex justify-end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="success"
              @click="addInfoPedido"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>Adicionar grade</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="error"
              class="mr-3"
              @click="removeInfoPedido"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
          </template>
          <span>Romover grade</span>
        </v-tooltip>
      </v-container>
      <v-row v-for="(info, i) in pedido.infoPedido" :key="i" class="mx-1">
        <v-col cols="1">
          <v-text-field
            v-model="pedido.infoPedido[i].qtd"
            label="QTD"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="pedido.infoPedido[i].modelo"
            label="Modelo"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="pedido.infoPedido[i].tecido"
            label="Tecido"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="pedido.infoPedido[i].valUnit"
            label="Valor unitário"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="pedido.infoPedido[i].valTot"
            label="Valor total"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-container class="mb-1 d-flex justify-end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="success"
              @click="addGrade"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>Adicionar grade</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="error"
              class="mr-3"
              @click="removeGrade"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
          </template>
          <span>Romover grade</span>
        </v-tooltip>
      </v-container>
      <v-row
        v-for="(grade, idx) in pedido.gradePedido"
        :key="'A' + idx"
        class="mx-1"
      >
        <v-col cols="3">
          <v-text-field
            v-model="pedido.gradePedido[idx].modelo"
            label="Modelo"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model="pedido.gradePedido[idx].pp"
            label="PP"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model="pedido.gradePedido[idx].p"
            label="P"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model="pedido.gradePedido[idx].m"
            label="M"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model="pedido.gradePedido[idx].g"
            label="G"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model="pedido.gradePedido[idx].gg"
            label="GG"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model="pedido.gradePedido[idx].eg"
            label="EG"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model="pedido.gradePedido[idx].exg"
            label="EXG"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="pedido.gradePedido[idx].esp"
            label="Especial"
            color="success"
            filled
            dark
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-row>
    <v-container class="mt-10 mb-3 d-flex justify-center">
      <v-btn
        v-if="edit == false"
        @click="sendPedido"
        large
        class="success mt-n5"
        >Cadastrar Pedido</v-btn
      >
      <v-btn v-else @click="editPedido" large class="success mt-n5"
        >Salvar pedido</v-btn
      >
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ["nameValue"],
  data: () => ({
    radios: "",
    edit: false,
    id: "",
    pedido: {
      name: "",
      email: "",
      cpf: "",
      cnpj: "",
      endereco: "",
      complemento: "",
      numero: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: "",
      telefone1: "",
      telefone2: "",
      manga: "",
      gola: "",
      tipoGola: "",
      pagamento: "",
      data: "",
      vendedor: "",
      infoPedido: [
        {
          qtd: "",
          modelo: "",
          tecido: "",
          valUnit: "",
          valTot: "",
        },
      ],
      gradePedido: [
        {
          modelo: "",
          pp: "",
          p: "",
          m: "",
          g: "",
          gg: "",
          eg: "",
          exg: "",
          esp: "",
        },
      ],
    },
  }),
  methods: {
    addInfoPedido() {
      if (this.pedido.infoPedido.length >= 1) {
        this.pedido.infoPedido.push({});
      } else {
        return;
      }
    },
    removeInfoPedido() {
      if (this.pedido.infoPedido.length == 1) {
        return;
      } else {
        this.pedido.infoPedido.pop();
      }
    },
    addGrade() {
      if (this.pedido.gradePedido.length >= 1) {
        this.pedido.gradePedido.push({});
      } else {
        return;
      }
    },
    removeGrade() {
      if (this.pedido.gradePedido.length == 1) {
        return;
      } else {
        this.pedido.gradePedido.pop();
      }
    },
    editPedido() {
      const totValArr = [];

      for (let i = 0; i < this.getOnePedido.infoPedido.length; i++) {
        totValArr.push(this.getOnePedido.infoPedido[i].valTot);
      }

      const valorTotal = totValArr.reduce((pre, next) => {
        return Number(pre) + Number(next);
      });

      this.pedido.valorPedido = `R$ ${valorTotal}`;

      const id = this.pedido._id;
      this.$http.patch(this.$pedidoApiRoute + id, this.pedido).then((res) => {
        this.$http.get(this.$pedidoApiRoute).then((response) => {
          this.$store.dispatch('callLoading')
          this.$swal({
            customClass: {
              title: "swal2-title-success",
            },
            title: "Pedido editado com sucesso!",
            toast: true,
            position: "top-end",
            timerProgressBar: true,
            timer: 3000,
            showConfirmButton: false,
            icon: "success",
            background: "#202A37",
            width: 400,
          });
          this.$store.dispatch("pedidos/closedialogEditPedido");
          this.$store.dispatch(
            "pedidos/setPedidos",
            response.data.data.pedidos
          );
        });
      });
    },
    sendPedido() {
      const verifyQtd = this.verifyQtd();
      if (verifyQtd === false) {
        this.$swal({
            customClass: {
              title: "swal2-title2",
            },
            title: "A quantidade da grade não confere com a quantidade total do pedido.",
            toast: true,
            position: "top-end",
            timerProgressBar: true,
            timer: 5000,
            showConfirmButton: false,
            icon: "error",
            background: "#202A37",
            width: '720'
          });
        return;
      }

      const totValArr = [];

      for (let i = 0; i < this.pedido.infoPedido.length; i++) {
        totValArr.push(this.pedido.infoPedido[i].valTot);
      }

      const valorTotal = totValArr.reduce((pre, next) => {
        return Number(pre) + Number(next);
      });

      this.pedido.valorPedido = `R$ ${valorTotal}`;

      this.$http.post(this.$pedidoApiRoute, this.pedido).then((res) => {
        this.$store.dispatch('callLoading')
        this.$swal({
          customClass: {
            title: "swal2-title-success",
          },
          title: "Pedido criado com sucesso!",
          toast: true,
          position: "top-end",
          timerProgressBar: true,
          timer: 3000,
          showConfirmButton: false,
          icon: "success",
          background: "#202A37",
          width: 400,
        });
        this.pedido.name = "";
        this.pedido.email = "";
        this.pedido.cnpj = "";
        this.pedido.cpf = "";
        this.pedido.endereco = "";
        this.pedido.complemento = "";
        this.pedido.numero = "";
        this.pedido.bairro = "";
        this.pedido.numero = "";
        this.pedido.bairro = "";
        this.pedido.cidade = "";
        this.pedido.uf = "";
        this.pedido.cep = "";
        this.pedido.telefone1 = "";
        this.pedido.telefone2 = "";
        this.pedido.manga = "";
        this.pedido.gola = "";
        this.pedido.tipoGola = "";
        this.pedido.pagamento = "";
        this.pedido.data = "";
        this.pedido.vendedor = "";
        this.pedido.infoPedido = [
          {
            qtd: "",
            modelo: "",
            tecido: "",
            valUnit: "",
            valTot: "",
          },
        ];
        this.pedido.gradePedido = [
          {
            modelo: "",
            pp: "",
            p: "",
            m: "",
            g: "",
            gg: "",
            eg: "",
            exg: "",
            esp: "",
          },
        ];
      });
    },
    verifyQtd() {
      let arrQtdPedidos = [];
      for (let i = 0; i < this.infoPedidoComputed.length; i++) {
        arrQtdPedidos.push(this.infoPedidoComputed[i].qtd);
      }

      let arrGrade = [];
      for (let k = 0; k < this.gradePedidoComputed.length; k++) {
        arrGrade.push(this.gradePedidoComputed[k].pp);
        arrGrade.push(this.gradePedidoComputed[k].p);
        arrGrade.push(this.gradePedidoComputed[k].m);
        arrGrade.push(this.gradePedidoComputed[k].g);
        arrGrade.push(this.gradePedidoComputed[k].gg);
        arrGrade.push(this.gradePedidoComputed[k].eg);
        arrGrade.push(this.gradePedidoComputed[k].exg);
        arrGrade.push(this.gradePedidoComputed[k].esp);
      }

      const formatedArrGrade = arrGrade.filter(Number)

      const sumPedidos = arrQtdPedidos.reduce(
        (prev, next) => Number(prev) + Number(next)
      );
      const sumGrade = formatedArrGrade.reduce(
        (prev, next) => Number(prev) + Number(next)
      );


      if (sumPedidos == sumGrade) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    getOnePedido() {
      return this.$store.getters["pedidos/getOnePedido"];
    },
    computedCnpj() {
      return this.pedido.cnpj;
    },
    qtdTabela() {
      return this.pedido.infoPedido[this.pedido.infoPedido.length - 1].qtd;
    },
    valUnitTabela() {
      return this.pedido.infoPedido[this.pedido.infoPedido.length - 1].valUnit;
    },
    infoPedidoComputed() {
      return this.pedido.infoPedido;
    },
    gradePedidoComputed() {
      return this.pedido.gradePedido;
    },
  },
  watch: {
    valUnitTabela: async function (val, oldVal) {
      try {
        let qtdTabela = [...this.qtdTabela];
        let valNum = Number(val);
        const real = qtdTabela.join("");
        this.infoPedidoComputed[this.pedido.infoPedido.length - 1].valTot =
          real * valNum;
      } catch (err) {
        console.log(err);
      }
    },
    computedCnpj: async function (val) {
      try {
        const reg = /([\./-])/g;
        const cnpjNum = await val.replace(reg, "");
        if (cnpjNum.length === 14 && cnpjNum) {
          const api = this.$getCnpjRoute + cnpjNum;
          this.$http.get(api).then((res) => {
            const data = res.data.data;
            const reg = /[a-z]+\s/gi;
            const name = data.nome.match(reg).join("");
            this.pedido.name = name;
            this.pedido.email = data.email;
            this.pedido.endereco = data.logradouro;
            this.pedido.complemento = data.complemento;
            this.pedido.numero = data.numero;
            this.pedido.bairro = data.bairro;
            this.pedido.cidade = data.municipio;
            this.pedido.uf = data.uf;
            this.pedido.cep = data.cep;
            this.pedido.telefone1 = data.telefone;
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    getOnePedido: function (val) {
      if (val.cpf) {
        this.radios = "fisica";
      } else if (val.cnpj) {
        this.radios = "juridica";
      }

      this.pedido.name = val.name;
      this.pedido.email = val.email;
      this.pedido.cnpj = val.cnpj;
      this.pedido.cpf = val.cpf;
      this.pedido.endereco = val.endereco;
      this.pedido.complemento = val.complemento;
      this.pedido.numero = val.numero;
      this.pedido.bairro = val.bairro;
      this.pedido.cidade = val.cidade;
      this.pedido.uf = val.uf;
      this.pedido.cep = val.cep;
      this.pedido.telefone1 = val.telefone1;
      this.pedido.telefone2 = val.telefone2;
      this.pedido.manga = val.manga;
      this.pedido.gola = val.gola;
      this.pedido.tipoGola = val.tipoGola;
      this.pedido.pagamento = val.pagamento;
      this.pedido.data = val.data;
      this.pedido.vendedor = val.vendedor;
      this.pedido.infoPedido = val.infoPedido;
      this.pedido.gradePedido = val.gradePedido;
      this.pedido._id = val._id;
      this.edit = true;
    },
  },
  mounted() {
    this.$http.get(this.$pedidoApiRoute).then((response) => {
      this.$store.dispatch("pedidos/setPedidos", response.data.data.pedidos);
    });

    const getAsyncData = async () => {
      try {
        if (this.$store.getters["pedidos/dialogEditPedido"] === true) {
          this.edit = true;

          const pedidoStore = await this.$store.getters["pedidos/getOnePedido"];

          if (pedidoStore.cpf) {
            this.radios = "fisica";
          } else if (pedidoStore.cnpj) {
            this.radios = "juridica";
          }

          this.pedido.name = pedidoStore.name;
          this.pedido.email = pedidoStore.email;
          this.pedido.cnpj = pedidoStore.cnpj;
          this.pedido.cpf = pedidoStore.cpf;
          this.pedido.endereco = pedidoStore.endereco;
          this.pedido.complemento = pedidoStore.complemento;
          this.pedido.numero = pedidoStore.numero;
          this.pedido.bairro = pedidoStore.bairro;
          this.pedido.cidade = pedidoStore.cidade;
          this.pedido.uf = pedidoStore.uf;
          this.pedido.cep = pedidoStore.cep;
          this.pedido.telefone1 = pedidoStore.telefone1;
          this.pedido.telefone2 = pedidoStore.telefone2;
          this.pedido.manga = pedidoStore.manga;
          this.pedido.gola = pedidoStore.gola;
          this.pedido.tipoGola = pedidoStore.tipoGola;
          this.pedido.pagamento = pedidoStore.pagamento;
          this.pedido.data = pedidoStore.data;
          this.pedido.vendedor = pedidoStore.vendedor;
          this.pedido.infoPedido = pedidoStore.infoPedido;
          this.pedido.gradePedido = pedidoStore.gradePedido;
          this.pedido._id = pedidoStore._id;
        } else {
          this.edit = false;
        }
      } catch (err) {
        console.log(err);
      }
    };

    getAsyncData();
  },
};
</script>