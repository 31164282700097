<template>
  <v-row>
    <v-col cols="12">
      <v-card class="secondary py-5 px-10">
        <v-row>
          <v-col>
            <h1 class="ml-6 colorText--text text-center mt-2 mb-3">
              Informações do cliente
            </h1>
          </v-col>
        </v-row>
        <v-col cols="11" class="d-flex justify-center mx-auto">
          <v-checkbox
            v-model="reserva.turma"
            label="EI"
            color="success"
            value="EI"
            hide-details
            dark
            class="mx-10"
          ></v-checkbox>
          <v-checkbox
            v-model="reserva.turma"
            label="FI"
            color="success"
            value="FI"
            hide-details
            dark
            class="mx-10"
          ></v-checkbox>
          <v-checkbox
            v-model="reserva.turma"
            label="FII"
            color="success"
            value="FII"
            hide-details
            dark
            class="mx-10"
          ></v-checkbox>
          <v-checkbox
            v-model="reserva.turma"
            label="Ens. Médio"
            color="success"
            value="Ens. Médio"
            hide-details
            dark
            class="mx-10"
          ></v-checkbox>
          <v-checkbox
            v-model="reserva.turma"
            label="Outro"
            color="success"
            value="Outro"
            hide-details
            dark
            class="mx-10"
          ></v-checkbox>
        </v-col>
        <v-row class="mt-5">
          <v-col cols="4">
            <v-text-field
              label="Nome do cliente"
              color="success"
              v-model="reserva.nome"
              filled
              dark
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Telefone do cliente"
              color="success"
              v-model="reserva.telefone"
              filled
              dark
              v-mask="'(##) #####-####'"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Email"
              color="success"
              v-model="reserva.email"
              filled
              dark
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Observações do pedido"
              color="success"
              v-model="reserva.obs"
              filled
              dark
            >
            </v-text-field>
          </v-col>
          <v-container class="px-0 d-flex justify-end mt-n6" fluid>
            <v-switch
              color="success"
              dark
              v-model="reserva.pago"
              :label="`Pedido está pago? ${
                reserva.pago === false ? 'Não' : 'Sim'
              }`"
            ></v-switch>
            <v-switch
              class="ml-10"
              color="success"
              dark
              v-model="reserva.entrega"
              :label="`Pedido para entrega? ${
                reserva.entrega === false ? 'Não' : 'Sim'
              }`"
            ></v-switch>
          </v-container>
          <v-row v-if="reserva.entrega">
            <v-col cols="4">
              <v-text-field
                v-model="reserva.cep"
                label="Cep"
                color="success"
                filled
                dark
                v-mask="'##.###-###'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="reserva.rua"
                label="Rua"
                color="success"
                filled
                dark
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="reserva.bairro"
                label="Bairro"
                color="success"
                filled
                dark
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="reserva.cidade"
                label="Cidade"
                color="success"
                filled
                dark
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="reserva.complemento"
                label="Complemento"
                color="success"
                filled
                dark
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="reserva.numero"
                label="Numero"
                color="success"
                filled
                dark
                v-mask="'######'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="reserva.uf"
                label="UF"
                color="success"
                filled
                dark
                v-mask="'AA'"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-col cols="12">
            <h1 class="ml-6 mt-5 colorText--text text-center">
              Peças do cliente
            </h1>
            <v-container class="mb-1 d-flex justify-end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="success"
                    @click="addReserva"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar peça de uniforme</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="error"
                    class="mr-3"
                    @click="removeReserva"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </template>
                <span>Romover peça de uniforme</span>
              </v-tooltip>
            </v-container>
          </v-col>
          <v-row
            v-for="(pecas, i) in reserva.pecas"
            :key="i"
            class="d-flex justify-center"
          >
            <v-col cols="1">
              <v-text-field
                label="QTD"
                color="success"
                v-model="reserva.pecas[i].qtd"
                filled
                dark
              >
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                label="Nome da peça"
                color="success"
                v-model="reserva.pecas[i].nomePeca"
                filled
                dark
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                label="Tamanho"
                color="success"
                v-model="reserva.pecas[i].tam"
                filled
                dark
              >
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-select
                label="Escola"
                color="success"
                v-model="reserva.pecas[i].escola"
                filled
                dark
                :items="escolas"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-row>
        <v-container v-if="dialogEditReserva" class="mt-10 mb-3 d-flex justify-center">
          <v-btn @click="editReserva" large class="success mt-n2"
            >Editar reserva</v-btn
          >
        </v-container>
        <v-container v-else class="mt-10 mb-3 d-flex justify-center">
          <v-btn @click="sendReserva" large class="success mt-n2"
            >Cadastrar reserva</v-btn
          >
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      escolas: [],
      reserva: {
        entrega: false,
        pago: false,
        loja: "",
        nome: "",
        telefone: "",
        email: "",
        obs: "",
        rua: "",
        bairro: "",
        numero: "",
        uf: "",
        cidade: "",
        complemento: "",
        cep: "",
        turma: [],
        pecas: [
          {
            qtd: "",
            nomePeca: "",
            tam: "",
            escola: "",
          },
        ],
      },
    };
  },
  methods: {
    closeModal() {
      this.$store.dispatch("reservas/closeEditReserva");
    },
    addReserva() {
      this.reserva.pecas.push({});
    },
    removeReserva() {
      if (this.reserva.pecas.length === 1) {
        return;
      } else {
        this.reserva.pecas.pop();
      }
    },
    sendReserva() {
      this.reserva.loja = this.$store.getters.user.store;
      this.$http.post(this.$reservaApiRoute, this.reserva).then((res) => {
        if (res.status == 201) {
          this.$store.dispatch("callLoading");
          this.$swal({
            customClass: {
              title: "swal2-title-success",
            },
            title: "Reserva efetuada com sucesso!",
            toast: true,
            position: "top-end",
            timerProgressBar: true,
            timer: 3000,
            showConfirmButton: false,
            icon: "success",
            background: "#202A37",
            width: 400,
          });
          this.reserva.entrega = false;
          this.reserva.pago = false;
          this.reserva.obs = "";
          this.reserva.loja = "";
          this.reserva.nome = "";
          this.reserva.email = "";
          this.reserva.telefone = "";
          this.reserva.escola = "";
          this.reserva.rua = "";
          this.reserva.bairro = "";
          this.reserva.cidade = "";
          this.reserva.complemento = "";
          this.reserva.cep = "";
          this.reserva.numero = "";
          this.reserva.uf = "";
          this.reserva.turma = [];
          this.reserva.pecas = [{}];
        }
      });
    },
    editReserva() {
      const id = this.oneReserva._id
      this.reserva.loja = this.$store.getters.user.store;
      this.$http.patch(this.$reservaApiRoute + id, this.reserva).then((res) => {
         this.closeModal()
        if (res.status == 200) {
          this.$store.dispatch("callLoading");
          this.$swal({
            customClass: {
              title: "swal2-title-success",
            },
            title: "Reserva efetuada com sucesso!",
            toast: true,
            position: "top-end",
            timerProgressBar: true,
            timer: 3000,
            showConfirmButton: false,
            icon: "success",
            background: "#202A37",
            width: 400,
          });
          this.reserva.entrega = false;
          this.reserva.pago = false;
          this.reserva.obs = "";
          this.reserva.loja = "";
          this.reserva.nome = "";
          this.reserva.email = "";
          this.reserva.telefone = "";
          this.reserva.escola = "";
          this.reserva.rua = "";
          this.reserva.bairro = "";
          this.reserva.cidade = "";
          this.reserva.complemento = "";
          this.reserva.cep = "";
          this.reserva.numero = "";
          this.reserva.uf = "";
          this.reserva.turma = [];
          this.reserva.pecas = [{}];
          this.$http.get(this.$reservaApiRoute).then((response) => {
          this.$store.dispatch(
            "reservas/setReservas",
            response.data.data.reserva
          );
        });
        }
      });
    },
  },
  computed: {
    cep() {
      return this.reserva.cep;
    },
    oneReserva() {
      return this.$store.getters["reservas/getOneReserva"];
    },
    dialogEditReserva() {
      return this.$store.getters['reservas/dialogEditReserva']
    }
  },
  watch: {
    oneReserva: function (val, oldVal) {
      this.reserva.turma = val.turma;
      this.reserva.nome = val.nome;
      this.reserva.telefone = val.telefone;
      this.reserva.email = val.email;
      this.reserva.obs = val.obs
        if(val.entrega) {
        this.reserva.cep = val.cep
        this.reserva.rua = val.rua
        this.reserva.bairro = val.bairro
        this.reserva.cidade = val.cidade
        this.reserva.complemento = val.complemento
        this.reserva.numero = val.numero
        this.reserva.uf = val.uf
      }
      this.reserva.pecas = val.pecas

    },
    cep: async function (val) {
      try {
        if (val.length === 10) {
          this.$http.get(this.$getCepRoute + val).then((res) => {
            const data = res.data.data;
            this.reserva.bairro = data.bairro;
            this.reserva.cidade = data.cidade;
            this.reserva.rua = data.logradouro;
            this.reserva.uf = data.uf;
          });
        }
      } catch (err) {
      }
    },
  },
  mounted() {
    this.$http.get(this.$reservaApiRoute + 'escolas').then(res => {
      this.escolas = res.data.data[0].escolas
    })

    if (this.$store.getters["reservas/dialogEditReserva"]) {
      const data = this.$store.getters["reservas/getOneReserva"];
      this.reserva.turma = data.turma;
      this.reserva.nome = data.nome;
      this.reserva.telefone = data.telefone;
      this.reserva.email = data.email;
      this.reserva.obs = data.obs
      this.reserva.pago = data.pago
      this.reserva.entrega = data.entrega
      if(data.entrega) {
        this.reserva.cep = data.cep
        this.reserva.rua = data.rua
        this.reserva.bairro = data.bairro
        this.reserva.cidade = data.cidade
        this.reserva.complemento = data.complemento
        this.reserva.numero = data.numero
        this.reserva.uf = data.uf
      }
      this.reserva.pecas = data.pecas
    }
  },
};
</script>