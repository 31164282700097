import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Print from 'vue-print-nb'
import VueSweetalert2 from 'vue-sweetalert2';
import './assets/css/index.css'
import interceptorSetup from './helpers/interceptor'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

Vue.use(new VueSocketIO({
    debug: true,
    connection: SocketIO('https://vestementa-dash.herokuapp.com/'), //options object is Optional
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

// Prod Routes
Vue.prototype.$userApiRoute = 'https://vestementa-dash.herokuapp.com/api/v1/user'
Vue.prototype.$pedidoApiRoute = 'https://vestementa-dash.herokuapp.com/api/v1/pedidos/'
Vue.prototype.$getCnpjRoute = 'https://vestementa-dash.herokuapp.com/api/v1/cnpj/'
Vue.prototype.$reservaApiRoute = 'https://vestementa-dash.herokuapp.com/api/v1/reservas/'
Vue.prototype.$getCepRoute = 'https://vestementa-dash.herokuapp.com/api/v1/cep/'
Vue.prototype.$sendEmailRoute = 'https://vestementa-dash.herokuapp.com/api/v1/email/'
Vue.prototype.$filaRoute = 'https://vestementa-dash.herokuapp.com/api/v1/fila/'


// Local routes
// Vue.prototype.$userApiRoute = 'https://localhost:3000/api/v1/user'
// Vue.prototype.$pedidoApiRoute = 'http://localhost:3000/api/v1/pedidos/'
// Vue.prototype.$getCnpjRoute = 'http://localhost:3000/api/v1/cnpj/'
// Vue.prototype.$reservaApiRoute = 'http://localhost:3000/api/v1/reservas/'
// Vue.prototype.$getCepRoute = 'http://localhost:3000/api/v1/cep/'
// Vue.prototype.$sendEmailRoute = 'http://localhost:3000/api/v1/email/'
// Vue.prototype.$filaRoute = 'http://localhost:3000/api/v1/fila/'

interceptorSetup()

Vue.use(VueSweetalert2);

Vue.use(Print)

Vue.use(VueAxios, axios)

Vue.use(VueMask);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
