<template>
  <v-app>
    <v-container>
      <router-view />
      <Loading/>
    </v-container>
  </v-app>
</template>

<script>
import Loading from './components/Loading.vue'

export default {
  name: "App",
  components: {Loading},
  data: () => ({
    //
  }),
};
</script>

<style scoped>
#app {
  background-color: #11171d;
}

@media (min-width: 1080px) {
  .container {
    max-width: 1920px;
  }
}
</style>