<template>
  <v-card class="secondary">
    <v-card-title>
      <v-text-field
        dark
        v-model="search"
        label="Pesquisar pedidos"
        single-line
        append-icon="mdi-magnify"
        hide-details
        color="success"
        placeholder="Digite qualquer informação sobre o pedido"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="secondary"
      dark
      :headers="headers"
      :items="allPedidos"
      :search="search"
      sort-by="_id"
      :sort-desc="true"
    >
      <template v-slot:item.acoes="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              center
              large
              color="success"
              v-bind="attrs"
              v-on="on"
              @click="sendWhats(props.item)"
            >
              <v-icon size="20"> mdi-whatsapp</v-icon>
            </v-btn>
          </template>
          <span>Enviar Whatsapp para o cliente</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              center
              large
              color="yellow"
              v-bind="attrs"
              v-on="on"
              @click="acessarPedido(props.item)"
            >
              <v-icon size="20"> mdi-file-document</v-icon>
            </v-btn>
          </template>
          <span>Visualizar e imprimir o pedido</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="editarPedido(props.item)"
              v-bind="attrs"
              v-on="on"
              icon
              center
              large
              color="blue"
            >
              <v-icon size="20"> mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Editar Pedido</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="deletarPedido(props.item._id)"
              v-bind="attrs"
              v-on="on"
              icon
              center
              large
              color="error"
            >
              <v-icon size="20"> mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Deletar pedido</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nome do cliente / Empresa",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email", align: "center", sortable: false },
        {
          text: "Telefone",
          value: "telefone1",
          align: "center",
          sortable: false,
        },
        {
          text: "Data de entrega",
          value: "data",
          align: "center",
          sortable: false,
        },
        {
          text: "Vendedor",
          value: "vendedor",
          align: "center",
          sortable: false,
        },
        {
          text: "Valor do pedido",
          value: "valorPedido",
          align: "center",
          sortable: false,
        },
        {
          text: "Ações do pedido",
          value: "acoes",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    sendWhats(item) {
      const reg = /\w+/g;
      const oldNumber = item.telefone1.match(reg);
      const url = `https://wa.me/55${+oldNumber.join("")}`;
      window.open(url, "_blank");
    },
    acessarPedido(pedido) {
      this.$store.dispatch("pedidos/openPreview", pedido);
    },
    editarPedido(pedido) {
      this.$store.dispatch("pedidos/openEdit", pedido);
    },
    deletarPedido(id) {
      this.$swal({
        customClass: {
          title: "swal2-title-error",
          confirmButton: "swal2-btn-confirm",
          cancelButton: "swal2-btn-cancel",
        },
        background: "#202A37",
        title: "Você realmente deseja deletar esse pedido?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#16C177",
        cancelButtonColor: "#f27474",
        confirmButtonText: "Desejo excluir o pedido!",
        cancelButtonText: "Cancelar!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('callLoading')
          this.$swal({
            customClass: {
              title: "swal2-title-success",
            },
            title: "Pedido deletado com sucesso!",
            toast: true,
            position: "top-end",
            timerProgressBar: true,
            timer: 3000,
            showConfirmButton: false,
            icon: "success",
            background: "#202A37",
            width: 400,
          });
          this.$http.delete(this.$pedidoApiRoute + id).then((res) => {
            this.$http.get(this.$pedidoApiRoute).then((response) => {
              this.$store.dispatch(
                "pedidos/setPedidos",
                response.data.data.pedidos
              );
            });
          });
        }
      });
    },
  },
  computed: {
    allPedidos() {
      return this.$store.getters["pedidos/getAllPedidos"];
    },
  },
  mounted() {
  const getAsyncData = async () => {
      try {
        await this.$http.get(this.$reservaApiRoute).then((response) => {
          this.$store.dispatch(
            "reservas/setReservas",
            response.data.data.reserva
          );
        });

        await this.$store.getters["reservas/getAllReservas"];
      } catch (err) {
        console.log(err);
      }
    };

    getAsyncData();
  },
};
</script>