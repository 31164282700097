var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"secondary"},[_c('v-card-title',[_c('v-text-field',{attrs:{"dark":"","label":"Pesquisar pedidos","single-line":"","append-icon":"mdi-magnify","hide-details":"","color":"success","placeholder":"Digite qualquer informação sobre o pedido"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"secondary",attrs:{"dark":"","headers":_vm.headers,"items":_vm.allPedidos,"search":_vm.search,"sort-by":"_id","sort-desc":true},scopedSlots:_vm._u([{key:"item.acoes",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","center":"","large":"","color":"success"},on:{"click":function($event){return _vm.sendWhats(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-whatsapp")])],1)]}}],null,true)},[_c('span',[_vm._v("Enviar Whatsapp para o cliente")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","center":"","large":"","color":"yellow"},on:{"click":function($event){return _vm.acessarPedido(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar e imprimir o pedido")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","center":"","large":"","color":"blue"},on:{"click":function($event){return _vm.editarPedido(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Pedido")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","center":"","large":"","color":"error"},on:{"click":function($event){return _vm.deletarPedido(props.item._id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar pedido")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }