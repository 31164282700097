<template>
  <v-card class="secondary">
    <v-card-title>
      <v-text-field
        dark
        v-model="search"
        label="Pesquisar clientes na fila"
        single-line
        append-icon="mdi-magnify"
        hide-details
        color="success"
        placeholder="Digite qualquer informação sobre a reserva"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="secondary"
      dark
      :headers="headers"
      :items="allFila"
      :search="search"
      sort-by="_id"
      :sort-desc="false"
    >
      <template v-slot:item.acoes="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              center
              large
              color="success"
              v-bind="attrs"
              v-on="on"
              @click="callUserWpp(props.item)"
            >
              <v-icon size="20"> mdi-whatsapp</v-icon>
            </v-btn>
          </template>
          <span>Avisar via whatsapp</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="callUser(props.item)"
              v-bind="attrs"
              v-on="on"
              icon
              center
              large
              color="blue"
            >
              <v-icon size="20"> mdi-account-alert</v-icon>
            </v-btn>
          </template>
          <span>Chamar o cliente</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditarReserva />
  </v-card>
</template>

<script>
import EditarReserva from "../components/EditarReserva.vue";

export default {
  components: { EditarReserva },
  data() {
    return {
      search: "",
      allFila: [],
      headers: [
        {
          text: "Nome do cliente",
          align: "start",
          sortable: false,
          value: "nome",
        },
        {
          text: "Telefone",
          value: "telefone",
          align: "center",
          sortable: false,
        },
        {
          text: "Loja",
          value: "loja",
          align: "center",
          sortable: false,
        },
        {
          text: "Ações da fila",
          value: "acoes",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    setFila() {
      this.$http.get(this.$filaRoute).then((res) => {
        this.allFila = res.data.data;
      });
    },
    callUser(item) {
      this.$swal({
        customClass: {
          title: "swal2-title-success",
          confirmButton: "swal2-btn-confirm",
          cancelButton: "swal2-btn-cancel",
        },
        background: "#202A37",
        title: "Você realmente deseja atender este cliente?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#16C177",
        cancelButtonColor: "#f27474",
        confirmButtonText: "Desejo atender-lo!",
        cancelButtonText: "Cancelar!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("callLoading");
          this.$swal({
            customClass: {
              title: "swal2-title-success",
            },
            title: "Cliente chamado com sucesso!",
            toast: true,
            position: "top-end",
            timerProgressBar: true,
            timer: 3000,
            showConfirmButton: false,
            icon: "success",
            background: "#202A37",
            width: 400,
          });
          this.$http
            .patch(this.$filaRoute + item._id, { atendido: true, telefone: item.telefone })
            .then((res) => {
              if (res) {
                this.setFila();
              }
            });
        }
      });
    },
    callUserWpp(item) {
      const reg = /\w+/g;
      const number = item.telefone.match(reg).join('');
      const mensagem = 'VesteMenta%20informa:%20Sua%20vez%20chegou!%20Dirija-se%20ao%20balcão,%20que%20um%20de%20nossos%20vendedores%20está%20te%20esperando,%20boas%20compras!'
      const url = `https://wa.me/55${number}?text=${mensagem}`;
      window.open(url, '_blank')
    }
  },
  created() {
    this.setFila();
    this.sockets.subscribe('fila', (data) => {
      this.setFila();
    });
  },
  destroyed() {
    this.sockets.unsubscribe('fila');
  },
};
</script>