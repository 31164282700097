import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import AdminView from '../views/AdminView.vue'
import PedidosView from '../views/PedidosView.vue'
import ReservaUniformeView from '../views/ReservaUniformeView.vue'
import FilaView from '../views/FilaView.vue'
import PreviewPedido from '../components/PreviewPedido.vue'
import vuex from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView
  },
  {
    path: '/auth/dashboard-vestementa',
    component: AdminView,
    name: 'dash',
    beforeEnter: async (to, from, next) => {
      if(from.name === 'home' || vuex.getters.user._id) {
        next()
      } else {
        router.push({path: '/'})
      }
    },
    children: [{
      path: '/',
      component: PedidosView,
      name: 'pedidos'
    },
    {
      path: 'reserva-uniformes',
      component: ReservaUniformeView,
      name: 'reservas'
    },
    {
      path: 'fila',
      component: FilaView,
      name: 'fila'
    },
    ]
  },
  {
    path: '/preview-pedido',
    component: PreviewPedido,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
