<template>
  <v-row>
    <v-col cols="12">
      <p class="mt-5 success--text">VesteMenta Uniformes ltda</p>
      <p style="font-size: 0.8rem" class="mt-5 colorText--text">
        Pedido: {{ getOnePedido._id }}
      </p>
    </v-col>
    <v-col cols="12">
      <h1 class="success--text">Informações do cliente</h1>
    </v-col>
    <v-col cols="4">
      <p class="mt-3 blue--text">Nome</p>
      <p>{{ getOnePedido.name }}</p>
    </v-col>
    <v-col cols="4">
      <p class="mt-3 blue--text">Email</p>
      <p>{{ getOnePedido.email }}</p>
    </v-col>
    <v-col cols="4" v-if="getOnePedido.cpf">
      <p class="mt-3 blue--text"  >CPF</p>
      <p>{{ getOnePedido.cpf }}</p>
    </v-col>
     <v-col cols="4" v-else>
      <p class="mt-3 blue--text" >CNPJ</p>
      <p>{{ getOnePedido.cnpj }}</p>
    </v-col>
    <v-col cols="5">
      <p class="mt-3 blue--text">Endereço</p>
      <p>{{ getOnePedido.endereco }}</p>
    </v-col>
    <v-col cols="5">
      <p class="mt-3 blue--text">Complemento</p>
      <p>{{ getOnePedido.complemento }}</p>
    </v-col>
    <v-col cols="2">
      <p class="mt-3 blue--text">Numero</p>
      <p>{{ getOnePedido.numero }}</p>
    </v-col>
    <v-col cols="5">
      <p class="mt-3 blue--text">Bairro</p>
      <p>{{ getOnePedido.bairro }}</p>
    </v-col>
    <v-col cols="5">
      <p class="mt-3 blue--text">Cidade</p>
      <p>{{ getOnePedido.cidade }}</p>
    </v-col>
    <v-col cols="2">
      <p class="mt-3 blue--text">UF</p>
      <p>{{ getOnePedido.uf }}</p>
    </v-col>
    <v-col cols="5">
      <p class="mt-3 blue--text">Modelo</p>
      <p>{{ getOnePedido.tipoGola }}</p>
    </v-col>
    <v-col cols="5">
      <p class="mt-3 blue--text">Manga</p>
      <p>{{ getOnePedido.manga }}</p>
    </v-col>
    <v-col cols="2">
      <p class="mt-3 blue--text">Tipo da gola</p>
      <p>{{ getOnePedido.gola }}</p>
    </v-col>
    <v-col cols="5">
      <p class="mt-3 blue--text">Pagamento</p>
      <p>{{ getOnePedido.pagamento }}</p>
    </v-col>
    <v-col cols="5">
      <p class="mt-3 blue--text">Data de entrega do Pedido</p>
      <p>{{ getOnePedido.data }}</p>
    </v-col>
    <v-col cols="2">
      <p class="mt-3 blue--text">Vendedor</p>
      <p>{{ getOnePedido.vendedor }}</p>
    </v-col>
    <v-col cols="12">
      <h1 class="success--text my-5">Informações do pedido</h1>
      <v-row v-for="(infoPedido, idx) in getOnePedido.infoPedido" :key="idx">
        <v-col cols="2">
          <p class="mt-3 blue--text">Quantidade</p>
          <p>
            {{ infoPedido.qtd }}
          </p>
        </v-col>
        <v-col cols="3">
          <p class="mt-3 blue--text">Modelo</p>
          <p>
            {{ infoPedido.modelo }}
          </p>
        </v-col>
        <v-col cols="3">
          <p class="mt-3 blue--text">Tecido</p>
          <p>
            {{ infoPedido.tecido }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="mt-3 blue--text">Valor Unitário</p>
          <p>
            {{ infoPedido.valUnit }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="mt-3 blue--text">Valor Total</p>
          <p>
            {{ infoPedido.valTot }}
          </p>
        </v-col>
      </v-row>
      <h1 class="success--text my-5">Grade do pedido</h1>
      <v-row
        v-for="(gradePedido, idx) in getOnePedido.gradePedido"
        :key="'A' + idx"
      >
        <v-col cols="3">
          <p class="mt-3 blue--text">Modelo</p>
          <p>
            {{ gradePedido.modelo }}
          </p>
        </v-col>
        <v-col cols="1">
          <p class="mt-3 blue--text">PP</p>
          <p>
            {{ gradePedido.pp }}
          </p>
        </v-col>
        <v-col cols="1">
          <p class="mt-3 blue--text">P</p>
          <p>
            {{ gradePedido.p }}
          </p>
        </v-col>
        <v-col cols="1">
          <p class="mt-3 blue--text">M</p>
          <p>
            {{ gradePedido.m }}
          </p>
        </v-col>
        <v-col cols="1">
          <p class="mt-3 blue--text">G</p>
          <p>
            {{ gradePedido.g }}
          </p>
        </v-col>
        <v-col cols="1">
          <p class="mt-3 blue--text">GG</p>
          <p>
            {{ gradePedido.gg }}
          </p>
        </v-col>
        <v-col cols="1">
          <p class="mt-3 blue--text">EG</p>
          <p>
            {{ gradePedido.eg }}
          </p>
        </v-col>
        <v-col cols="1">
          <p class="mt-3 blue--text">EXG</p>
          <p>
            {{ gradePedido.exg }}
          </p>
        </v-col>
        <v-col cols="2">
          <p class="mt-3 blue--text">Especial</p>
          <p>
            {{ gradePedido.esp }}
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    getOnePedido() {
      return this.$store.getters["pedidos/getOnePedido"];
    },
  },
};
</script>

<style>
h1 {
  color: #bdbdbd;
}
p {
  font-size: 1.3rem;
  font-weight: 500;
  color: #bdbdbd;
}
</style>