<template>
  <div class="ma-12 pa-12">
    <v-card>
      <v-navigation-drawer class="secondary" app permanent expand-on-hover>
        <v-list class="secondary">
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="../assets/vestementaAvatar.jpg"></v-img>
            </v-list-item-avatar>
            <v-list-item-title
              style="
                font-family: poppins !important;
                font-size: 1rem !important;
                font-weight: bold;
              "
              class="text-h6 colorText--text"
            >
              {{ getUserName.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider class="primary"></v-divider>

        <v-list class="secondary" nav dense>
          <v-list-item link @click="goToPedidos">
            <v-list-item-icon>
              <v-icon color="colorText">mdi-file-document-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="
                font-family: poppins !important;
                font-weight: bold;
                font-size: 1rem !important;
              "
              class="colorText--text"
              >Pedidos</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="goToReserva">
            <v-list-item-icon>
              <v-icon color="colorText">mdi-file-sign</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="
                font-family: poppins !important;
                font-weight: bold;
                font-size: 0.9rem !important;
              "
              class="colorText--text"
              >Reserva de uniforme</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="goToFila">
            <v-list-item-icon>
              <v-icon color="colorText">mdi-human-queue</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="
                font-family: poppins !important;
                font-weight: bold;
                font-size: 0.9rem !important;
              "
              class="colorText--text"
              >Fila de atendimento</v-list-item-title
            >
          </v-list-item>
          <!-- <v-list-item link>
            <v-list-item-icon>
              <v-icon color="colorText">mdi-point-of-sale</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="
                font-family: poppins !important;
                font-weight: bold;
                font-size: 0.9rem !important;
              "
              class="colorText--text"
              >Registro de vendas</v-list-item-title
            >
          </v-list-item> -->
          <!--  <v-list-item link>
            <v-list-item-icon>
              <v-icon color="colorText">mdi-desktop-mac-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-family: poppins !important; font-weight: bold; font-size: 0.9rem !important" class="colorText--text">Dashboard</v-list-item-title>
          </v-list-item>-->
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon color="colorText">mdi-close-circle-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="
                font-family: poppins !important;
                font-weight: bold;
                font-size: 0.9rem !important;
              "
              class="colorText--text"
              >Sair</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <v-container>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goToPedidos() {
      this.$router.push({ name: "pedidos" });
    },
    goToReserva() {
      this.$router.push({ name: "reservas" });
    },
     goToFila() {
      this.$router.push({ name: "fila" });
    },
    logout() {
      this.$swal({
        customClass: {
          title: "swal2-title-success",
        },
        title: "Logout efetuado com sucesso!",
        toast: true,
        position: "top-end",
        timerProgressBar: true,
        timer: 3000,
        showConfirmButton: false,
        icon: "success",
        background: "#202A37",
        width: 400,
      });
      this.$store.dispatch("callLoading");
      this.$router.push({ name: "home" });
    },
  },
  computed: {
    getUserName() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style>
</style>