<template>
  <div>
    <v-container class="mt-n15">
      <h1 class="success--text text-center" v-if="storeName">
        Fila digital do(a) {{ storeName }}
      </h1>
      <h1 class="success--text text-center" v-else>Fila de todas as lojas</h1>
    </v-container>
    <TabelaFila />
  </div>
</template>

<script>
import TabelaFila from "../components/TabelaFila.vue";
import vuex from "../store/index";
export default {
  components: { TabelaFila },
  data() {
    return {
      storeName: "",
    };
  },
  computed: {
    store() {
      return vuex.getters.user.store;
    },
  },
  mounted() {
    this.$store.dispatch("callLoading");
    if (this.store == "IS") {
      this.storeName = "Ilha de Santa Maria";
    } else if (this.store == "RB") {
      this.storeName = "Rio Branco";
    } else if (this.store == "VV") {
      this.storeName = "Vila Velha";
    }
  },
};
</script>

<style>
</style>