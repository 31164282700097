<template>
  <v-row class="mt-n15">
    <v-col cols="12">
      <h1 class="success--text text-center">
        Cadastro e visualização de pedidos
      </h1>
    </v-col>
    <v-col cols="12">
      <v-tabs
        grow
        color="success"
        background-color="secondary"
        slider-color="success"
        style="font-family: poppins !important; font-weight: bold !important"
        dark
      >
        <v-tab @click="navPedido = true">Registrar pedido</v-tab>
        <v-tab @click="navPedido = false">Todos os pedidos</v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="12" v-if="navPedido"> 
      <RegistroPedido/>
    </v-col>
     <v-col cols="12" v-else> 
      <TabelaPedido/>
    </v-col>
    <PedidoDialog/>
    <EditarPedido/>
  </v-row>
</template>

<script>
import PedidoDialog from "../components/PedidoDialog.vue";
import RegistroPedido from "../components/RegistoPedido.vue";
import TabelaPedido from "../components/TabelaPedido.vue";
import EditarPedido from "../components/EditarPedido.vue";

export default {
  components: { RegistroPedido, TabelaPedido, PedidoDialog, EditarPedido },
  data() {
    return { navPedido: true };
  },
};
</script>

<style>
</style>