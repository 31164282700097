<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogEditReserva"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="primary">
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Editar reserva de {{ getOneReserva.nome }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <v-container class="px-10">
          <ReservaUniforme />
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ReservaUniforme from "./ReservaUniforme.vue";

export default {
  components: { ReservaUniforme },
  methods: {
    closeModal() {
      this.$store.dispatch("reservas/closeEditReserva");
    },
  },
  computed: {
    dialogEditReserva() {
      return this.$store.getters["reservas/dialogEditReserva"];
    },
    getOneReserva() {
      return this.$store.getters["reservas/getOneReserva"];
    },
  },
};
</script>