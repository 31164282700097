<template>
  <div>
    <v-container class="mt-n15">
      <h1 class="success--text text-center" v-if="storeName">
        Reserva de uniformes {{ storeName }}
      </h1>
      <h1 class="success--text text-center" v-else>
        Reservas de todas as lojas
      </h1>
    </v-container>
    <v-container fluid>
      <v-tabs
        grow
        color="success"
        background-color="secondary"
        slider-color="success"
        style="font-family: poppins !important; font-weight: bold !important"
        dark
      >
        <v-tab @click="navPedido = true">Reservar uniforme</v-tab>
        <v-tab @click="navPedido = false">Todas as reservas</v-tab>
      </v-tabs>
    </v-container>
    <v-container v-if="navPedido"> <ReservaUniforme /> </v-container>
    <v-container v-else> <TabelaReserva /></v-container>
    <ReservaDialog />
  </div>
</template>

<script>
import ReservaUniforme from "../components/ReservaUniforme.vue";
import TabelaReserva from "../components/TabelaReserva.vue";
import vuex from "../store/index";
import ReservaDialog from "../components/ReservaDialog.vue";

export default {
  components: { ReservaUniforme, TabelaReserva, ReservaDialog },
  data: () => {
    return { navPedido: true, storeName: "" };
  },
  computed: {
    store() {
      return vuex.getters.user.store;
    },
    async reservas() {
      return await vuex.getters['reservas/getAllReservas']
    }
  },
  mounted() {
    if (this.store == "IS") {
      this.storeName = "Ilha de Santa Maria";
    } else if (this.store == "RB") {
      this.storeName = "Rio Branco";
    } else if (this.store == "VV") {
      this.storeName = "Vila Velha";
    }

    this.$http.get(this.$reservaApiRoute).then((response) => {
        this.$store.dispatch(
            "reservas/setReservas",
            response.data.data.reserva
          );
    });
  },
};
</script>

<style>
</style>