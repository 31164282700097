<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="primary">
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Reserva de {{ getOneReserva.nome }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text v-print="printObj" color="success">
              <v-icon size="30" class="mr-3" color="success"
                >mdi-printer</v-icon
              >
              Imprimir
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container class="px-16 my-10 secondary">
          <PreviewReserva id="previewReserva" />
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import RegistroPedido from "./RegistoPedido.vue";
import PreviewReserva from './PreviewReserva.vue'

export default {
  components: { RegistroPedido, PreviewReserva },
  data() {
    return {
      printObj: {
        id: "previewReserva",
      },
    };
  },
  methods: {
    closeDialog() {
      this.$store.dispatch("reservas/closeDialogReserva");
    },
  },
  computed: {
    getOneReserva() {
      return this.$store.getters["reservas/getOneReserva"];
    },
    dialog() {
      return this.$store.getters["reservas/dialogReserva"];
    },
  },
};
</script>