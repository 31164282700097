<template>
  <div class="text-center">
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="200"
        :width="7"
        color="success"
        indeterminate
      ><v-img max-width="100" src="../assets/vestementaIcon.svg"></v-img></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import vuex from '../store/index'

export default {
  data: () => ({

  }),
  computed: {
    overlay() {
      return vuex.getters.overlay
    }
  }
 
};
</script>

<style>
</style>